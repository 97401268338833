import React from 'react';
import Link from 'components/Link';
import ApiContext from 'context/ApiContext';
import _isEmpty from 'lodash/isEmpty';
import useAuth from 'hooks/useAuth';

const RegLink = ({ to, href, withLang, children, external, externalRoot, ...props }) => {
  const { getRegistraionURLWithParams } = useAuth();

  return (
    <ApiContext.Consumer>
      {(( { registrationURL, localRefId } ) => {
        const regUrl = getRegistraionURLWithParams(registrationURL, localRefId);
        return <Link to={regUrl} {...props} external externalSelf>{children}</Link>;
      })}
    </ApiContext.Consumer>
  );
};

export default RegLink;
