// extracted by mini-css-extract-plugin
export var section = "Referral-module--section--dBz4t";
export var layout = "Referral-module--layout--ZdPkp";
export var content = "Referral-module--content--pR4rE";
export var description = "Referral-module--description--mMCwY";
export var image = "Referral-module--image--OLNr+";
export var picture = "Referral-module--picture--jXWA8";
export var statsGold = "Referral-module--statsGold--Vcw2p";
export var statsGold4 = "Referral-module--statsGold4--SW1uV";
export var statsGold5 = "Referral-module--statsGold5--Yuwx1";
export var statsGold6 = "Referral-module--statsGold6--f+SqD";
export var blockAltText2 = "Referral-module--blockAltText2--4Vaj3";