import React from 'react';
import Link from 'components/Link';
import ahubBanner from "utils/animations/asic_hub_banner.json";
import Lottie from 'react-lottie';
import * as s from './Banner.module.scss';

const Banner = () => {
  return (
    <div className={s.banner}>
      <Link 
        data-gtm-category="site_main"
        data-gtm-action="banner_click"
        data-gtm-label={JSON.stringify({ button: 'new_asic_launched' })}
        withLang to="/asic/s19"
      >
        <Lottie
          options={{ animationData: ahubBanner }}
        />
      </Link> 
    </div>
  );
}

export default Banner;
