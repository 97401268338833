import React, {useEffect} from 'react';
import { useSpring } from 'react-spring';
import {useInView} from "react-intersection-observer";
import { translate3d } from 'hooks/useMouseMoveAnimate';

const useBlockAnimate = (props = {}) => {
	const {
		delay = 200,
		duration = 500,
		left = 0,
		top = 0,
		once = false,
		intersect = false,
		threshold = 0,
	} = props;
	const { ref, inView } = useInView({ threshold, triggerOnce: once });
	const show = intersect ? inView : true;
	const x = show ? 0 : left;
	const y = show ? 0 : top;
	const springStyles = useSpring({
		config: { duration, mass: 500, tension: 100 },
		opacity: show ? 1 : 0,
		transform: translate3d(x, y, 1),
		from: { opacity: 0, transform: translate3d(left, top, 1), },
		delay,
	});

	return {
		springStyles,
		ref,
		inView,
	};
}

export default useBlockAnimate;
