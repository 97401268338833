import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { animated } from 'react-spring';
import useBlockAnimate from 'hooks/useBlockAnimate';
import RegLink from 'components/RegLink';
import * as gs from 'pages/pages.module.css';
import * as s from './intro.module.scss';
import introBg from 'utils/images/intro-background.png';

const Intro = () => {
  const { springStyles: springStyles1 } = useBlockAnimate({ intersect: false, left: -30, duration: 800 });
  return (
    <>
      <section className={cx(s.root)}>
        <figure className={s.background} >
         <img src={introBg} alt="background" />
        </figure>
        <animated.h1 className={cx(gs.accent, s.title, 'title-trail')} style={springStyles1}>
          <FormattedMessage id="intro.title" defaultMessage="Your Mining Ecosystem" values={
            {
              span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
            }
          }/>
        </animated.h1>
        <p className={s.p}>
          <FormattedMessage id="intro.text" defaultMessage="Hassle-free mining. Predictable revenue stream. Maximum performance and speed."/>
        </p>
        <RegLink 
          data-gtm-category="site_main"
          data-gtm-action="get_started_click"
          data-gtm-label={JSON.stringify({ button: 'get_started', block: 'intro' })}
          external noRef 
          className="btn lg-x" 
          shadowElement={true}
        >
          <FormattedMessage id="get_started" defaultMessage="Get Started" />
        </RegLink>
      </section>
    </>
  );
}

export default Intro;
