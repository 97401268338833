import React from 'react';
// import cx from 'classnames';
import {FormattedMessage} from 'react-intl';
// import goldSvg from 'utils/images/piece-of-gold.svg';
import * as s from './Stats.module.scss';
import { useSpring, animated } from 'react-spring';
import useMouseMoveAnimate, { translate3d } from 'hooks/useMouseMoveAnimate';
import { useInView } from "react-intersection-observer";

const ANIMATION_DELAY = 500;

const Stats = () => {
	const { springProps, ref, inView } = useMouseMoveAnimate();
	const { ref: ref2, inView: inView2 } = useInView({ threshold: 0, triggerOnce: true });
	const { numberActiveMiners } = useSpring({
		from: { numberActiveMiners: 1 },
		to: { numberActiveMiners: inView2 ? 430 : 1 },
		delay: ANIMATION_DELAY,
	});
	const { numberUsers } = useSpring({
		from: { numberUsers: 1 },
		to: { numberUsers: inView2 ? 600 : 1 },
		delay: ANIMATION_DELAY,
	});
	const { numberTelegramSubscribers } = useSpring({
		from: { numberTelegramSubscribers: 1 },
		to: { numberTelegramSubscribers: inView2 ? 300 : 1 },
		delay: ANIMATION_DELAY,
	});
	const { numberFirmware } = useSpring({
		from: { numberFirmware: 1 },
		to: { numberFirmware: inView2 ? 15 : 1 },
		delay: ANIMATION_DELAY,
	});

	return (
		<section ref={ref} className={s.section}>
			<div className={s.wrapper}>
				<div ref={ref2} className={s.container}>
						{/* <animated.div className={cx(s.statsGold, s.statsGold1)} style={inView ? {transform: springProps.xy.to((x,y) => translate3d(x,y, 10, 'rotate(10deg)'))} : {}}>
							<img src={goldSvg} alt="" role="presentation" aria-hidden={true}/>
						</animated.div>
						<animated.div className={cx(s.statsGold, s.statsGold2)} style={inView ? {transform: springProps.xy.to((x,y) => translate3d(x,y, 18, 'rotate(-14deg)'))} : {}}>
							<img src={goldSvg} alt="" role="presentation" aria-hidden={true}/>
						</animated.div>
						<animated.div className={cx(s.statsGold, s.statsGold3)} style={inView ? {transform: springProps.xy.to((x,y) => translate3d(x,y, 13, 'rotate(-14deg)'))} : {}}>
							<img src={goldSvg} alt="" role="presentation" aria-hidden={true}/>
						</animated.div> */}
						<ul className={s.statsItems}>
							<li className={s.statsItem}>
								<div className={s.statsValue}>
									<animated.span>{numberActiveMiners.to(n => n.toFixed(0))}</animated.span>
									k
								</div>
								<span className={s.statsLabel}><FormattedMessage id="Active miners"/></span>
							</li>
							<li className={s.statsItem}>
								<div className={s.statsValue}>2M+</div>
								<span className={s.statsLabel}><FormattedMessage id="Workers"/></span>
							</li>
							<li className={s.statsItem}>
								<div className={s.statsValue}>
									<animated.span>{numberUsers.to(n => n.toFixed(0))}</animated.span>
									k
								</div>
								<span className={s.statsLabel}><FormattedMessage id="Mobile users"/></span>
							</li>
							<li className={s.statsItem}>
								<div className={s.statsValue}>
									<animated.span>{numberTelegramSubscribers.to(n => n.toFixed(0))}</animated.span>
									k
								</div>
								<span className={s.statsLabel}><FormattedMessage id="Telegram subscribers"/></span>
							</li>
							<li className={s.statsItem}>
								<div className={s.statsValue}>
									<animated.span>{numberFirmware.to(n => n.toFixed(0))}</animated.span>
								</div>
								<span className={s.statsLabel}><FormattedMessage id="Best ASIC firmware"/></span>
							</li>
						</ul>
					</div>
			</div>
		</section>
	);
}

export default Stats;
