// extracted by mini-css-extract-plugin
export var section = "Stats-module--section--UhJ-M";
export var container = "Stats-module--container--zVapN";
export var statsItems = "Stats-module--statsItems--0-e1-";
export var statsItem = "Stats-module--statsItem--SjPec";
export var statsValue = "Stats-module--statsValue--JTDc2";
export var statsLabel = "Stats-module--statsLabel--0dp4X";
export var statsGold = "Stats-module--statsGold--vn4Da";
export var statsGold1 = "Stats-module--statsGold1--tWJrM";
export var statsGold2 = "Stats-module--statsGold2--c-SSW";
export var statsGold3 = "Stats-module--statsGold3--zsEsz";
export var wrapper = "Stats-module--wrapper--pTfNw";