// extracted by mini-css-extract-plugin
export var root = "Blocks-module--root--nOHYK";
export var blockAlt = "Blocks-module--blockAlt--JJuf3";
export var blockAltImage = "Blocks-module--blockAltImage--12MLV";
export var scrollableImage = "Blocks-module--scrollableImage--ajqtF";
export var blockAltMore = "Blocks-module--blockAltMore--x3si7";
export var title = "Blocks-module--title--JaYWy";
export var titleSmall = "Blocks-module--titleSmall--Oy9aL";
export var description = "Blocks-module--description--5ZEXq";
export var descriptionEfficiently = "Blocks-module--descriptionEfficiently--ozVNt";
export var profitableDescription = "Blocks-module--profitableDescription--CoKUD";
export var descriptionGetExtra = "Blocks-module--descriptionGetExtra--ceoV4";
export var descriptionFirmware = "Blocks-module--descriptionFirmware--2w3zg";
export var highlightedTextAlt = "Blocks-module--highlightedTextAlt--4ZspC";
export var headCols = "Blocks-module--headCols--lEnW8";
export var headColsText = "Blocks-module--headColsText--e7nyK";
export var devices = "Blocks-module--devices--iWvPc";
export var device = "Blocks-module--device--JPTur";
export var device1 = "Blocks-module--device1--DmXVB";
export var device2 = "Blocks-module--device2--gFLY9";
export var deviceMeta = "Blocks-module--deviceMeta--3bsYm";
export var deviceMeta1 = "Blocks-module--deviceMeta1--XYjod";
export var deviceMeta2 = "Blocks-module--deviceMeta2--UQ+qV";
export var deviceLabel = "Blocks-module--deviceLabel--K+Tgg";
export var deviceModels = "Blocks-module--deviceModels--stgeK";
export var deviceModelsPrimary = "Blocks-module--deviceModelsPrimary--O2wP2";
export var deviceModelsAlt = "Blocks-module--deviceModelsAlt--bCwHg";
export var blockText = "Blocks-module--blockText--JuBlz";
export var moreLinks = "Blocks-module--moreLinks--DX6rk";
export var orangeLight = "Blocks-module--orangeLight--Oq2Xc";
export var blueLight = "Blocks-module--blueLight--7CIfc";
export var ethBg = "Blocks-module--ethBg--rq17L";
export var getExtraLight = "Blocks-module--getExtraLight--Gb-Vs";
export var yellowLight = "Blocks-module--yellowLight--9Ll6L";
export var pinkBlue = "Blocks-module--pinkBlue--+cnGm";
export var lightSizeV1 = "Blocks-module--lightSizeV1--tk27V";
export var lightSizeV2 = "Blocks-module--lightSizeV2--8X3FJ";
export var lightSizeV3 = "Blocks-module--lightSizeV3---WgFA";
export var lightSizeV4 = "Blocks-module--lightSizeV4--IWkep";
export var ll50 = "Blocks-module--ll50--0t0-u";
export var ll60 = "Blocks-module--ll60--FSVNU";
export var lr50 = "Blocks-module--lr50--Yihyu";
export var lr60 = "Blocks-module--lr60--zpjTV";
export var lt0 = "Blocks-module--lt0--R7VK7";
export var lt15 = "Blocks-module--lt15--4mkR7";
export var lt20 = "Blocks-module--lt20--YfhWs";
export var ltn20 = "Blocks-module--ltn20--xwnbl";
export var ltn25 = "Blocks-module--ltn25--boxBG";
export var ltn30 = "Blocks-module--ltn30--xfaTF";
export var ltn35 = "Blocks-module--ltn35--hDVNm";
export var ltn60 = "Blocks-module--ltn60--Lv8rl";
export var blockAltText2 = "Blocks-module--blockAltText2--juCZI";
export var blockAltText1 = "Blocks-module--blockAltText1--bUJm-";