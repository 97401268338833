import React, {useEffect} from 'react';
import { useSpring } from 'react-spring';
import {useInView} from "react-intersection-observer";
import { translate3d } from 'hooks/useMouseMoveAnimate';

const useImageAnimate = (props = {}) => {
	const {
		delay = 200,
		duration = 500,
		top = 50,
		once = false,
	} = props;
	const { ref, inView } = useInView({ threshold: 0, triggerOnce: once });
	const springImageStyles = useSpring({
		config: { duration, },
		opacity: inView ? 1 : 0,
		transform: translate3d(0, inView ? 0 : top, 1),
		from: { opacity: 0, transform: translate3d(0, top, 1), },
		delay,
	});

	return {
		springImageStyles,
		ref,
		inView,
	};
}

export default useImageAnimate;
