import React from 'react';
import cx from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { animated } from 'react-spring';
import useMouseMoveAnimate, { translate3d } from 'hooks/useMouseMoveAnimate';
import RegLink from 'components/RegLink';
import Link from 'components/Link';
import useBlockAnimate from 'hooks/useBlockAnimate';
import useImageAnimate from 'hooks/useImageAnimate';
import * as s from './Blocks.module.scss';
// TODO: generate images automatically
// png
import hiveOsPng from 'utils/images/set-up-control-and-mine.png';
import hiveOsRuPng from 'utils/images/set-up-control-and-mine_ru.png';
import hiveOsMobilePng from 'utils/images/set-up-control-and-mine-mobile.png';
import hiveOsMobileRuPng from 'utils/images/set-up-control-and-mine-mobile_ru.png';
import coinPoolPng from 'utils/images/coin-pool.png';
import coinPoolRuPng from 'utils/images/coin-pool_ru.png';
import coinPoolMobilePng from 'utils/images/coin-pool-mobile.png';
import coinPoolMobileRuPng from 'utils/images/coin-pool-mobile_ru.png';
import asicsPng from 'utils/images/asic-hub.png';
import asicsRunPng from 'utils/images/asic-hub_ru.png';
import oldAsicPng from 'utils/images/old-asic.png';
import newAsicPng from 'utils/images/new-asic.png';
// webp
import hiveOsWebp from 'utils/images/set-up-control-and-mine.webp';
import hiveOsRuWebp from 'utils/images/set-up-control-and-mine_ru.webp';
import hiveOsMobileWebp from 'utils/images/set-up-control-and-mine-mobile.webp';
import hiveOsMobileRuWebp from 'utils/images/set-up-control-and-mine-mobile_ru.webp';
import coinPoolWebp from 'utils/images/coin-pool.webp';
import coinPoolRuWebp from 'utils/images/coin-pool_ru.webp';
import coinPoolMobileWebp from 'utils/images/coin-pool-mobile.webp';
import coinPoolMobileRuWebp from 'utils/images/coin-pool-mobile_ru.webp';
import asicsWebp from 'utils/images/asic-hub.webp';
import asicsRunWebp from 'utils/images/asic-hub_ru.webp';
import oldAsicWebp from 'utils/images/old-asic.webp';
import newAsicWebp from 'utils/images/new-asic.webp';
const Blocks = ({ intl }) => {
	const { locale } = intl;
	let hiveOsPngLocale = hiveOsPng;
	let hiveOsMobilePngLocale = hiveOsMobilePng;
	let coinPoolPngLocale = coinPoolPng;
	let coinPoolMobilePngLocale = coinPoolMobilePng;
	let asicsPngLocale = asicsPng;
	let hiveOsWebpLocale = hiveOsWebp;
	let hiveOsMobileWebpLocale = hiveOsMobileWebp;
	let coinPoolWebpLocale = coinPoolWebp;
	let coinPoolMobileWebpLocale = coinPoolMobileWebp;
	let asicsWebpLocale = asicsWebp;
	if (locale === 'ru') {
		hiveOsPngLocale = hiveOsRuPng;
		hiveOsMobilePngLocale = hiveOsMobileRuPng;
		coinPoolPngLocale = coinPoolRuPng;
		asicsPngLocale = asicsRunPng;
		coinPoolMobilePngLocale = coinPoolMobileRuPng;
		hiveOsWebpLocale = hiveOsRuWebp;
		hiveOsMobileWebpLocale = hiveOsMobileRuWebp;
		coinPoolWebpLocale = coinPoolRuWebp;
		asicsWebpLocale = asicsRunWebp;
		coinPoolMobileWebpLocale = coinPoolMobileRuWebp;
	}

	const { ref: ref1, springImageStyles: springImageStyles1 } = useImageAnimate({ delay: 200, once: true });
	const { ref: ref2, springImageStyles: springImageStyles2 } = useImageAnimate({ delay: 200, once: true });
	const { ref: ref3, springImageStyles: springImageStyles3 } = useImageAnimate({ delay: 200, once: true });
	const { ref: ref4, springImageStyles: springImageStyles4 } = useImageAnimate({ delay: 600, once: true });
	const { ref: ref5, springImageStyles: springImageStyles5 } = useImageAnimate({ delay: 900, once: true });
	const { springProps: springProps7, ref: ref7, inView: inView7 } = useMouseMoveAnimate({ disable: false });
	const { ref: ref10, springStyles: springTitleStyles10 } = useBlockAnimate({ intersect: true, delay: 200, top: 50, once: true });
	const { ref: ref11, springStyles: springTitleStyles11 } = useBlockAnimate({ intersect: true, delay: 200, top: 50, once: true });
	const { ref: ref12, springStyles: springTitleStyles12 } = useBlockAnimate({ intersect: true, delay: 200, top: 50, once: true });
	const { ref: ref13, springStyles: springTitleStyles13 } = useBlockAnimate({ intersect: true, delay: 200, top: 50, once: true });
	return (
		<section className={cx(s.root)}>
			<div className={cx(s.blockAlt)}>
				<div className={cx(s.orangeLight, s.lightSizeV1, s.ltn25, s.ll50)} aria-hidden={true}/>
				<div className={cx(s.blueLight, s.lightSizeV1, s.lt0, s.lr50)} aria-hidden={true}/>
				<animated.h2 ref={ref10} className={s.title} style={springTitleStyles10}>
					<FormattedMessage
						id="landing.title.1"
						defaultMessage="Manage all farms efficiently with Hive OS"
						values={
							{
								span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
								br: <br/>,
							}
						}
					/>
				</animated.h2>
				<p className={cx(s.description, s.descriptionEfficiently)}>
					<FormattedMessage
						id="landing.text.1"
						defaultMessage="Hive OS is the operating system that helps to keep your crypto farms at their peak and monitor their info in one place."
					/>
				</p>

				<div ref={ref1} className={cx(s.blockAltImage)}>
					<animated.picture style={springImageStyles1}>
						<source media="(max-width: 799px)" srcSet={hiveOsMobileWebpLocale} type="image/webp"/>
						<source media="(max-width: 799px)" srcSet={hiveOsMobilePngLocale} type="image/png"/>
						<source media="(min-width: 800px)" srcSet={hiveOsPngLocale} type="image/png"/>
						<source media="(min-width: 800px)" srcSet={hiveOsWebpLocale} type="image/webp"/>
						<img width={1240} height={722} src={hiveOsPngLocale} alt="Set up, control, and mine efficiently" loading="lazy"/>
					</animated.picture>
				</div>

				<div className={s.blockAltMore}>
					<RegLink 
            data-gtm-category="site_main"
            data-gtm-action="get_started_click"
            data-gtm-label={JSON.stringify({ button: 'get_started', block: 'hiveos' })}
            external 
            noRef 
            className="btn lg-x" 
            shadowElement={true}
          >
						<FormattedMessage id="Start mining" defaultMessage="Start mining"/>
					</RegLink>
				</div>
			</div>

			<div ref={ref7} className={cx(s.blockAlt)}>
				<div className={cx(s.pinkBlue, s.lightSizeV1, s.lr50, s.ltn60)} aria-hidden={true}/>
				{/* <div className={cx(s.blueLight, s.lightSizeV4, s.ll60, s.ltn20)} aria-hidden={true}/> */}
				<animated.div className={cx(s.ethBg, s.ll60, s.ltn20)} style={inView7 ? {transform: springProps7.xy.to((x,y) => translate3d(x,y, 15))} : {}} aria-hidden={true}/>
				<animated.h2 ref={ref11} className={s.title} style={springTitleStyles11}>
					<FormattedMessage
						id="landing.title.2"
						defaultMessage="Profitable Hiveon Pool for ETH, ETC mining"
						values={
							{
								span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
							}
						}
					/>
				</animated.h2>
				<p className={cx(s.description, s.profitableDescription)}>
					<FormattedMessage
						id="landing.text.2"
						defaultMessage="The high-performance mining pool with our secure worldwide servers and PPS+ reward type."
					/>
				</p>

				<div ref={ref2} className={cx(s.blockAltImage)}>
					<animated.picture style={springImageStyles2}>
						<source media="(max-width: 799px)" srcSet={coinPoolMobileWebpLocale} type="image/webp"/>
						<source media="(max-width: 799px)" srcSet={coinPoolMobilePngLocale} type="image/png"/>
						<source media="(min-width: 800px)" srcSet={coinPoolWebpLocale} type="image/webp"/>
						<source media="(min-width: 800px)" srcSet={coinPoolPngLocale} type="image/png"/>
						<img width={1240} height={677} src={coinPoolPngLocale} alt="Mine ETH, ETC" loading="lazy"/>
					</animated.picture>
				</div>
        <div className={s.blockAltMore}>
          <Link 
            data-gtm-category="site_main"
            data-gtm-action="pool_click"
            data-gtm-label={JSON.stringify({ button: 'start_mining' })}
            external externalRoot to="https://multisite.hiveon.dev/pool/" withLang className="btn lg-x" shadowElement={true}
          >
            <FormattedMessage id="get_started" defaultMessage="Get Started"/>
          </Link>
        </div>
			</div>

			<div className={cx(s.blockAlt)}>
				<div className={cx(s.blueLight, s.lightSizeV4, s.lr60, s.ltn35)} aria-hidden={true}/>
				<div className={cx(s.yellowLight, s.lightSizeV1, s.ll50, s.lt0)} aria-hidden={true}/>
				<animated.h2 ref={ref12} className={cx(s.title)} style={springTitleStyles12}>
					<FormattedMessage
						id="landing.title.3"
						defaultMessage="Connect 1000s of devices with ASIC Hub"
						values={
							{
								span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
								br: <br/>,
							}
						}
					/>
				</animated.h2>
				<p className={s.description}>
					<FormattedMessage
						id="landing.text.3"
						defaultMessage="Link the devices ultra-fast and use the benefit of Hive OS for free."
					/>
				</p>
				<div ref={ref3} className={cx(s.blockAltImage)}>
					<animated.picture style={springImageStyles3}>
						<source srcSet={asicsWebpLocale} type="image/webp"/>
						<img width={1240} height={696} src={asicsPngLocale} alt="Install thousands of ASICs within minutes" loading="lazy"/>
					</animated.picture>
				</div>
				<div className={s.blockAltMore}>
					<Link 
            data-gtm-category="site_main"
            data-gtm-action="asichub_click"
            data-gtm-label={JSON.stringify({ button: 'install_asics' })}
            withLang to="/asichub" className="btn lg-x" shadowElement={true}
          >
						<FormattedMessage id="Connect ASIC miners" defaultMessage="Connect ASIC miners"/>
					</Link>
				</div>
			</div>

			<div className={cx(s.blockAlt)}>
				<div className={cx(s.getExtraLight, s.lightSizeV1, s.lr60, s.ltn20)} aria-hidden={true}/>
				<div className={cx(s.blueLight, s.lightSizeV4, s.ll60, s.lt15)} aria-hidden={true}/>
				<animated.h2 ref={ref13} className={s.title} style={springTitleStyles13}>
					<FormattedMessage
						id="landing.title.4"
						defaultMessage="Protect and accelerate with ASIC Firmware"
						values={
							{
								span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
								br: <br/>,
							}
						}
					/>
				</animated.h2>
				<p className={cx(s.description, s.descriptionGetExtra)}>
					<FormattedMessage
						id="landing.text.4"
						defaultMessage="Get extra hashrate from your devices and save them from viruses."
					/>
				</p>
				<div className={cx(s.blockAltImage, s.devices)}>
					<div ref={ref4} className={cx(s.device, s.device1)}>
						<animated.div style={springImageStyles4}>
							<picture>
								<source srcSet={oldAsicWebp} type="image/webp"/>
								<img width={800} height={693} src={oldAsicPng} alt="Old Asic" loading="lazy"/>
							</picture>
							<div className={cx(s.deviceMeta, s.deviceMeta1)}>
								<span className={s.deviceLabel}>
									Antminer S9
								</span>
									<span className={s.deviceModels}>
									<span className={s.deviceModelsPrimary}>S9</span>
									<span className={s.deviceModelsAlt}>S9/S9j/S9i</span>
								</span>
							</div>
						</animated.div>
					</div>
					<div ref={ref5} className={cx(s.device, s.device2)}>
						<animated.div style={springImageStyles5}>
							<picture>
								<source srcSet={newAsicWebp} type="image/webp"/>
								<img width={650} height={769} src={newAsicPng} alt="New Asic" loading="lazy"/>
							</picture>
							<div className={cx(s.deviceMeta, s.deviceMeta2)}>
							<span className={s.deviceLabel}>
								3 Pro Max
							</span>
								<span className={s.deviceModels}>
								<span className={s.deviceModelsPrimary}>S19 / Pro</span>
								<span className={s.deviceModelsAlt}>S19/S19j/S19j Pro/S19 Pro</span>
							</span>
							</div>
						</animated.div>
					</div>
				</div>
				<div className={s.blockAltMore}>
					<div className={cx('highlighted-text', s.titleSmall)}>
						<FormattedMessage
							id="firmware.label"
							defaultMessage="firmware for popular ASIC"
						/>
					</div>
					<p className={cx(s.description, s.descriptionFirmware)}>
						<FormattedMessage
								id="firmware.description"
								defaultMessage="firmware for popular ASIC"
							/>
					</p>
					<Link 
            data-gtm-category="site_main"
            data-gtm-action="asic_click"
            data-gtm-label={JSON.stringify({ button: 'get_started_with_firmware' })}
            withLang to="/asic" className="btn lg-x" shadowElement={true}
          >
						<FormattedMessage id="Get Started with Firmware" defaultMessage="Get Started with Firmware"/>
					</Link>
				</div>
			</div>
		</section>
	);
}

export default injectIntl(Blocks);
