import React from 'react';
import cx from 'classnames';
import {FormattedMessage} from 'react-intl';
import { animated } from 'react-spring';
import useMouseMoveAnimate, { translate3d } from 'hooks/useMouseMoveAnimate';
import useBlockAnimate from 'hooks/useBlockAnimate';
import * as gs from 'pages/pages.module.css';
import WL from 'constants/whiteLabel';
import Link from 'components/Link';
import * as s from './Referral.module.scss';
import capsulePng from 'utils/images/capsule.png';

const Referral = () => {

	const { ref: refTitle, springStyles: springTitleStyles } = useBlockAnimate({ intersect: true, delay: 200, top: 50, once: true });
	const { ref: refDescription, springStyles: springDescriptionStyles } = useBlockAnimate({ intersect: true, delay: 300, top: 50, once: true });
	const { springProps, ref: ref, inView: inView } = useMouseMoveAnimate({ disable: false });

	const transformStyle = {
		transform: springProps.xy.to((x,y) => translate3d(x,y, 25))
	}

	return (
			<section className={cx(s.section)}>
				<div className={cx(s.layout)}>
					<div className={s.content}>
						<animated.h2 ref={refTitle} className={gs.accent} style={springTitleStyles}>
							<FormattedMessage id="Referral title"/>
						</animated.h2>
						<animated.p ref={refDescription} style={springDescriptionStyles} className={s.description}>
							<FormattedMessage
								id="landing.text.5"
								defaultMessage="Share the link with your mates and get 10% from your referral payments each time they deposit."
								values={
									{
										span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
									}
								}
							/>
						</animated.p>
						<Link 
							data-gtm-category="site_main"
							data-gtm-action="get_started_click"
							data-gtm-label={JSON.stringify({ button: 'get_your_referral_link' })}
							external 
							noRef 
							href={WL.referralLink} 
							className="btn btn-lg" 
							shadowElement={true}
						>
							<FormattedMessage id="GetYourRefferalLink" defaultMessage="Get your referral link"/>
						</Link>
					</div>

					<animated.div ref={ref} style={inView ? transformStyle : {}}  className={s.image}>
						<picture className={s.picture}>
							<img width={557} height={338} src={capsulePng} alt="Referral rewards" loading="lazy"/>
						</picture>
					</animated.div>
				</div>
			</section>
	);
}

export default Referral;
