import React from 'react';
import cx from 'classnames';
import Banner from 'components/Home/Banner';
import Intro from 'components/Home/Intro';
import Blocks from 'components/Home/Blocks';
import Stats from 'components/Home/Stats';
import Referral from 'components/Home/Referral';
import * as s from './Landing.module.scss';

const Landing = () => {
	return (
		<div className={cx(s.root)}>
      <Banner/>
			<Intro/>
			<Stats/>
			<div className={s.blocks}>
				<Blocks/>
				<Referral/>
			</div>
		</div>
	);
}

export default Landing;
