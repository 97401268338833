import React, { Component } from 'react'
import Layout from 'components/layout'
import Cookies from 'js-cookie'
import _get from 'lodash/get';
import GetStarted from 'components/GetStarted';
import Bottom from 'components/Bottom/Bottom';
import Testimonials from 'components/Testimonials/testimonials';
import Landing from 'components/Home/Landing';
import { REF_ID } from 'constants/index';
import WL from 'constants/whiteLabel';
import './customStyles.css';

const isProd = process.env.BUILD_ENV === 'production';

class IndexPage extends Component {
  state = {
    isShowModal: false,
    animateHowItWork: false,
    noAnimation: false,
  };

  openModal = () => {
    this.setState({
      animateHowItWork: !this.state.animateHowItWork
    })

    setTimeout(()=>{
      this.setState({
        isShowModal: !this.state.isShowModal
      })
      this.onPlay();
    }, 600);
  }

  closeModal = () => {
    this.setState({
      isShowModal: !this.state.isShowModal
    })

    setTimeout(()=>{
      this.setState({
        animateHowItWork: !this.state.animateHowItWork
      })
    }, 200);

  }

  _onReady = (event) => {
    setTimeout(() => {
      event.target.playVideo();
    }, 400);
  }

  render() {
    if ( this.props.location.search ) {
      const ref = new URLSearchParams(this.props.location.search).get('ref');
      const isRefExistAndNotSet = ref !== null && Cookies.get(REF_ID) === undefined
      if (isRefExistAndNotSet) {
        Cookies.set(REF_ID, ref, {
          expires: 365,
          path: '/',
          ...(isProd ? {
            domain: '.hiveon.com',
          } : {})
        });
      }
    }

    return (
      <Layout lang={_get(this.props, 'pageContext.lang', 'en')} isHome={true} isDarkBg={true} registrationURL={_get(this.props, 'pageContext.registrationURL', '')}>
        <div>
          <Landing/>
          {WL.hasTestimonials() && <Testimonials/>}
          {WL.hasTestimonials() && <GetStarted/>}
          <Bottom/>
        </div>
      </Layout>
    )
  }

}

export default IndexPage;
